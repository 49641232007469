import React, { useState, useEffect } from "react";
import { ProgressBar, Button } from "react-bootstrap";
import Tabs from "components/tabs";
import Icon from "components/icon";
import Modal from "components/modal";
import {
  RenderButtonPanel,
  RenderDetails,
  RenderSelectStatusModal,
} from "./components";
import { phoneNormalize } from "utils/helpers";
import PickupImageUploadForm from "forms/pickup-image-upload-form";
import Notes from "./notes";

const SinglePickup = ({
  pickup,
  setCurrentPickup,
  numeric,
  startPickup,
  updatePickup,
  pickupStatuses,
  common,
}) => {
  const [tab, setTab] = useState("details");
  const [phoneModal, setPhoneModal] = useState(false);
  const [confirmStatusModal, setConfirmStatusModal] = useState(false);
  const [imagesBadge, setImagesBadge] = useState(0);
  const [notesBadge, setNotesBadge] = useState(0);

  const pickupStatus = common.pickupStatuses[pickup.statusId].label;

  const isPending = pickupStatus === "Pending";
  const isCancelled = pickupStatus === "Cancelled";
  useEffect(() => {
    if (pickup?.imagesCount && !imagesBadge) {
      setImagesBadge(pickup.imagesCount);
    }
  }, [pickup]);

  const calculateBar = () => {
    if (!!pickup) {
      if (pickup.nextStopNotificationSent && !pickup.arrivedAt) {
        return 25;
      }
      if (pickup.arrivedAt && isPending) {
        return 75;
      }
      if (!isPending) {
        return 100;
      }
    }
    return 0;
  };

  const tabs = pickup
    ? {
        details: (
          <RenderDetails
            location={pickup.location}
            pickupType={pickup.pickupType.label}
            title={pickup.user.fullName}
          />
        ),
        images: (
          <PickupImageUploadForm
            pickupId={pickup.id}
            setBadge={setImagesBadge}
            mobileMode
          />
        ),
        notes: (
          <Notes
            locationId={pickup.location.id}
            pickupId={pickup.id}
            userId={pickup.userId}
            driverMode
            setBadge={setNotesBadge}
          />
        ),
      }
    : {};

  return pickup ? (
    <>
      <div className="driver-pickup-single-wrapper ">
        <div className="driver-pickup-top">
          <div>
            <Button
              onClick={() => setCurrentPickup(null)}
              className="driver-pickup-top-return-btn"
              variant="link"
            >
              Back to list
            </Button>
          </div>
          <div>
            Pickup {numeric.current} of {numeric.all}
          </div>
        </div>
        <div className="driver-pickup-progress-wrapper">
          <ProgressBar now={calculateBar()} />
          <div className="scale">
            <div>Started</div>
            <div>Arrived</div>
            <div>{isPending ? "Status" : pickupStatus}</div>
          </div>
        </div>
        <div className="driver-pickup-tabs d-flex justify-content-center">
          <Tabs fullWidth active={tab} onChange={setTab}>
            <Tabs.Tab value={"details"}>Details</Tabs.Tab>
            <Tabs.Tab
              loading={imagesBadge === -1}
              badge={imagesBadge}
              value={"images"}
            >
              Images
            </Tabs.Tab>
            <Tabs.Tab
              loading={notesBadge === -1}
              badge={notesBadge}
              value={"notes"}
            >
              Notes
            </Tabs.Tab>
          </Tabs>
        </div>

        {Object.entries(tabs).map(([currentTab, component]) => (
          <div
            className={currentTab !== tab ? "d-none tab-item" : "tab-item"}
            key={currentTab}
          >
            {component}
          </div>
        ))}
        <RenderButtonPanel
          isPending={isPending}
          isCancelled={isCancelled}
          openPhones={setPhoneModal}
          pickup={pickup}
          setCurrentPickup={setCurrentPickup}
          startPickup={startPickup}
          updatePickup={updatePickup}
          setConfirmStatusModal={setConfirmStatusModal}
        />
      </div>
      <Modal
        show={phoneModal}
        title={"Select a Phone Number"}
        onHide={() => setPhoneModal(false)}
      >
        <div className="driver-phone-numbers-modal">
          {pickup.user.phoneNumbers.map((phone) => {
            return (
              <Button
                variant="outline-primary"
                onClick={() => {
                  window.open(`tel:${phoneNormalize(phone.number)}`);
                }}
              >
                <Icon style={{ marginRight: "1rem" }} type="phone" />
                <span>{phone.number}</span>;
              </Button>
            );
          })}
        </div>
      </Modal>
      <RenderSelectStatusModal
        pickupStatuses={pickupStatuses}
        setShow={setConfirmStatusModal}
        show={confirmStatusModal}
        onConfirm={(statusId) => {
          updatePickup({
            ...pickup,
            statusId,
          }).then((res) => {
            setCurrentPickup({ ...pickup, ...res });
            setConfirmStatusModal(false);
          });
        }}
      />
    </>
  ) : (
    <></>
  );
};

export default SinglePickup;
