import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  ListGroup,
  ProgressBar,
  Row,
  FormCheck,
} from "react-bootstrap";

import { FormControl } from "react-bootstrap";

import { getFormatedTime } from "utils/dates";
import Box from "components/box";
import Icon from "components/icon";
import Modal from "components/modal";
import SinglePickup from "../route-single-pickup";
import RouteDriverSelectDropdown from "./route-assign-driver";
import RouteTimesEstimateForm from "forms/create-update-time-windows";
import { REACT_APP_MP_OLD_ROUTING } from "config";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import LabelWithCount from "components/label-with-count-box";
import Loader from "components/loader";

const SingleRoute = ({
  noCollapse,
  requestRoutePickupsByRouteId,
  pickupDate,
  routeId,
  updateRoute,
  timeWindows,
  pickupRoute,
  furnitureTimeWindows,
  rehydrateRoutes,
  pickupTypes,
  pickupStatuses,
  auth,
  pickups,
  pickupsObj,
  showOnMap,
  setShowOnMap,
  pickupsMin,
  routesMap,
  unsavedPickupsStopList,
  reassignIn,
  reassignFromTo,
}) => {
  const [showTimeWindowModal, setShowTimeWindowModal] = useState(false);
  const [expanded, setExpanded] = useState(noCollapse || false);
  const [pickupsCount, setPickupsCount] = useState(0);
  const [pickupStatusesCount, setPickupStatusesCount] = useState({});

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const getPickups = () => {
    requestRoutePickupsByRouteId({ pickupDate, routeId });
  };

  const _closeModal = () => setShowTimeWindowModal(false);

  const openTimeWindowModal = () => setShowTimeWindowModal(true);

  const updateTimeWindow = (pickupWindows) => {
    return updateRoute({
      ...pickupRoute,
      ...pickupWindows,
    }).then(() => {
      _closeModal();
    });
  };

  const renderTimeWindowsModal = () => {
    const showModalContent = () => {
      const defaultTimeWindows =
        pickupRoute && pickupRoute.hasFurniture
          ? furnitureTimeWindows
          : timeWindows;
      const pickupWindowsToRender =
        pickupRoute.pickupWindows && pickupRoute.pickupWindows.length > 0
          ? pickupRoute.pickupWindows
          : defaultTimeWindows;
      return (
        <RouteTimesEstimateForm
          initialValues={{
            pickupWindows: pickupWindowsToRender,
          }}
          form={`pickupWindows`}
          onSubmit={updateTimeWindow}
          onSubmitSuccess={() => {
            rehydrateRoutes(pickupRoute.routeDate, pickupRoute.regionId);
          }}
        />
      );
    };
    return showTimeWindowModal ? (
      <Modal
        show={showTimeWindowModal}
        onHide={() => {
          _closeModal();
        }}
        backdrop={"static"}
        title={"Time Windows"}
      >
        {showModalContent()}
      </Modal>
    ) : null;
  };

  useEffect(() => {
    noCollapse && getPickups();
  }, []);

  useEffect(() => {
    let pickupsCountQ = 0;
    const pickupStatusesCountQ = {};

    // Loops through pickup statuses and creates an array for counting

    pickupStatuses.forEach((status) => {
      pickupStatusesCountQ[status.id] = 0;
    });

    for (const pickup of pickupsMin) {
      pickupStatusesCountQ[pickup.pickupStatus.id]++;
      pickupsCountQ++;
    }
    setPickupsCount(pickupsCountQ);
    setPickupStatusesCount(pickupStatusesCountQ);
  }, [pickupsMin]);

  const selectPickupType = (e, route, pickupRoute) => {
    const selected = pickupTypes.find(
      (pickupType) => pickupType.id === parseInt(e.target.value)
    );
    if (selected) {
      updateRoute({
        ...route,
        pickupTypeId: e.target.value,
      });
    }
  };

  const { pickupsLoaded, pickupsFetching } = pickupRoute;
  // If there are routes loop through them

  const pickupTypeId = pickupRoute.pickupTypeId;
  const expandedStyle = expanded ? {} : { display: "none" };

  // AUTH: If this is a driver return null for routes that he is not assigned to
  if (
    auth.credentials.userTypeId === 3 &&
    pickupRoute.driverId !== auth.credentials.id
  ) {
    return null;
  }

  // Get the route object ( this is a computed property that contains a pickup array within the route object)
  let route = pickupRoute;

  let routeLoading = routesMap[route.id]?.loadingStatus;

  // Count of pickups which are not in the "pending" state -- everything else has been handled and can go toward route completion
  const pickupsNotPendingCount = pickupsCount - pickupStatusesCount[1];
  return (
    <Box key={route.id}>
      {renderTimeWindowsModal()}
      {routeLoading ? (
        <Loader />
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>
              <Icon style={{ color: route.color }} type="truck" /> Truck{" "}
              {route.position + 1}{" "}
              {route.pickupType && "- " + route.pickupType.label + " "}
              {!noCollapse ? (
                <small>
                  (
                  <Button
                    variant="link"
                    style={{
                      padding: 0,
                      fontSize: "0.875rem",
                      fontWeight: 400,
                    }}
                    onClick={() => toggleExpand()}
                  >
                    {expanded ? "Hide Stops" : "Show Stops"}
                  </Button>
                  )
                </small>
              ) : null}
            </h3>
            {auth.credentials.userType.label !== "driver" &&
              REACT_APP_MP_OLD_ROUTING !== "true" && (
                <span style={{ display: "flex" }}>
                  Show on map:{" "}
                  <FormCheck
                    style={{ marginLeft: 5 }}
                    checked={showOnMap}
                    onChange={(e) => setShowOnMap(e.target.checked)}
                  />
                </span>
              )}
          </div>
          <ProgressBar
            // color={color}
            now={(pickupsNotPendingCount / pickupsMin.length) * 100 + 0.1}
          />

          <Row className={"mt-4"}>
            <Col sm={REACT_APP_MP_OLD_ROUTING === "true" ? "5" : "9"}>
              <div style={{ display: "flex" }}>
                {/*<div>Total Donations: {route.pickups.length}</div>*/}
                {pickupStatuses.map((status) => {
                  let count = pickupStatusesCount[status.id];
                  if (count > 0) {
                    return (
                      <LabelWithCount
                        color={status.color}
                        label={status.label}
                        count={pickupStatusesCount[status.id]}
                        key={status.id}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
            {REACT_APP_MP_OLD_ROUTING === "true" && (
              <Col sm="4" style={{ marginBottom: "15px" }}>
                <div>
                  <strong>Time Windows</strong>
                </div>
                {pickupRoute.pickupWindows &&
                  pickupRoute.pickupWindows.map((window) => (
                    <div>
                      <strong>
                        Stops {window.first} - {window.last}:
                      </strong>{" "}
                      {getFormatedTime(window.start)} -{" "}
                      {getFormatedTime(window.end)}
                    </div>
                  ))}
                <div>
                  {auth.credentials.userType.label !== "driver" && (
                    <Button onClick={openTimeWindowModal}>
                      Change Time Windows
                    </Button>
                  )}
                </div>
              </Col>
            )}
            <Col sm={"3"} style={{ marginBottom: "15px" }}>
              {auth.credentials.userType.label !== "driver" && (
                <RouteDriverSelectDropdown route={route} />
              )}
              {REACT_APP_MP_OLD_ROUTING === "true" &&
                auth.credentials.userType.label !== "driver" && (
                  <React.Fragment>
                    <FormControl
                      as="select"
                      value={pickupTypeId || -1}
                      onChange={(e) => selectPickupType(e, route, pickupRoute)}
                    >
                      {!pickupTypeId &&
                        auth.credentials.userType.label !== "driver" && (
                          <option value="-1">Select Pickup Type</option>
                        )}
                      {auth.credentials.userType.label !== "driver"
                        ? pickupTypes.map((pickupType) => (
                            <option value={pickupType.id}>
                              {pickupType.label}
                            </option>
                          ))
                        : null}
                    </FormControl>
                  </React.Fragment>
                )}
            </Col>
          </Row>
        </div>
      )}
      {(expanded || noCollapse) && (
        <ListGroup
          className="pickups"
          data-route-id={routeId}
          style={expandedStyle}
        >
          <DragDropContext
            // TODO: update stop number while dragging
            onDragUpdate={async (result) => {
              console.log(result);
            }}
            onDragEnd={async (result) => {
              const { source, destination, draggableId } = result;
              if (source?.index && destination?.index) {
                reassignIn(source.index - 1, destination.index - 1);
              }
            }}
          >
            <Droppable droppableId="routes">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {((noCollapse && pickups) || unsavedPickupsStopList || [])
                    .sort((a, b) => a.stopNumber - b.stopNumber)
                    .map((pickupMin, index) => {
                      return (
                        <Draggable
                          key={pickupMin.id}
                          draggableId={`${pickupMin.id}`}
                          index={pickupMin.stopNumber}
                          isDragDisabled={
                            auth.credentials.userType.label === "driver"
                          }
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SinglePickup
                                reassignIn={(destinationIdx) => {
                                  reassignIn(index, destinationIdx);
                                }}
                                reassignFromTo={(routeIdTo, destinationIdx) =>
                                  reassignFromTo(
                                    routeIdTo,
                                    index,
                                    destinationIdx
                                  )
                                }
                                routeId={routeId}
                                pickupMin={pickupMin}
                                index={index}
                                color={route.color}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ListGroup>
      )}
    </Box>
  );
};

export default SingleRoute;
