import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { Button, Form, ButtonToolbar } from "react-bootstrap";
import Icon from "components/icon";
import RenderIf from "hoc/render-if";
import { customFormat } from "utils/dates";
import { REACT_APP_MP_NO_IMAGE_URL } from "config";
const RenderFullscreenButton = ({ onClick, isFullScreen }) => {
  return (
    <Button
      onClick={onClick}
      variant="dark"
      className="position-absolute"
      style={{ top: 30, right: 0 }}
    >
      {isFullScreen ? <Icon type="close" /> : <Icon type="fullscreen" />}
    </Button>
  );
};

const RenderDescription = ({ item }) => {
  const { user, createdAt } = item;
  const { fullName, userType } = user;
  return (
    <div className={"container-fluid px-4 py-2"}>
      <div className={"lh-1 row"}>
        <div className={"col"}>
          {fullName}
          <span className={"badge bg-primary ms-1"}>{userType.label}</span>
        </div>
        <div className={"col text-end"}>
          {customFormat(createdAt, "MM/DD/YYYY@h:mma ")}
        </div>
      </div>
    </div>
  );
};

const RenderCategoryInput = ({
  setSelectedCategory,
  selectedCategory,
  pickupImageCategories,
}) => {
  return (
    <Form.Group className="d-flex align-items-center d-none">
      <Form.Label style={{ margin: 0, marginRight: "0.5rem" }}>
        Category
      </Form.Label>
      <Form.Control
        style={{ width: "112px" }}
        as="select"
        value={!!selectedCategory ? selectedCategory : 0}
        onChange={(e) => setSelectedCategory(e.target.value)}
        // custom
      >
        <option value={0}>All</option>
        {pickupImageCategories.map((category) => (
          <option value={category.id} key={category.id}>
            {category.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

const RenderDeleteButton = ({ deleteFn }) => {
  const [question, setQuestion] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ padding: "16px 0" }}>
      {question ? (
        <div style={{ display: "flex" }} className={"align-items-center"}>
          <div>Delete this image?</div>
          <Button
            disabled={loading}
            style={{ margin: "0 16px " }}
            onClick={async () => {
              setLoading(true);

              await deleteFn();
              setQuestion(false);
              setLoading(false);
            }}
            variant="primary"
          >
            {loading ? "Loading..." : "Delete"}
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              setQuestion(false);
            }}
            variant="link"
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => {
            setQuestion(true);
          }}
          variant="primary"
        >
          Delete
        </Button>
      )}{" "}
    </div>
  );
};

const PickupImagesDisplay = ({
  images,
  deleteImage,
  pickupImageCategories,
  mobileMode,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [imagesGallery, setImagesGallery] = useState([]);

  useEffect(() => {
    filterImagesGallery(images, selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, images]);

  const createImagesGallery = (data) => {
    const newData = data.map(({ url, ...rest }) => {
      return {
        dataItem: { url, ...rest },
        original: url,
        thumbnail: url,
      };
    });
    return newData;
  };

  const filterImagesGallery = (images, selectedCategory) => {
    const finalFn = (data) => {
      setCurrentImageIndex(0);
      setImagesGallery(data);
    };

    if (!images || !images.length) {
      return finalFn([]);
    }

    const imagesGallery = createImagesGallery(images);

    if (!!selectedCategory) {
      const numberCategory = Number.parseInt(selectedCategory);

      if (numberCategory !== 0) {
        const imagesGalleryFiltered = imagesGallery.filter((item) => {
          return item.dataItem.category.id === numberCategory;
        });
        return finalFn(imagesGalleryFiltered);
      }
    }
    return finalFn(imagesGallery);
  };

  const deleteImageHandler = () => {
    setCurrentImageIndex(0);
    deleteImage({
      id: imagesGallery[currentImageIndex].dataItem.id,
    });
  };

  const imagesLength = imagesGallery?.length;
  return (
    <>
      <div>
        <ButtonToolbar className="justify-content-between align-items-center">
          <div className={""}>
            <RenderIf condition={imagesLength > 0}>
              <RenderCategoryInput
                pickupImageCategories={pickupImageCategories}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
              />
            </RenderIf>
          </div>
        </ButtonToolbar>
        {imagesLength > 0 ? (
          <ImageGallery
            items={imagesGallery}
            showPlayButton={false}
            renderCustomControls={() => (
              <RenderDescription
                item={imagesGallery[currentImageIndex].dataItem}
              />
            )}
            onBeforeSlide={(currentIndex) => {
              setCurrentImageIndex(currentIndex);
            }}
          />
        ) : (
          <div className="image-gallery-empty-wrapper">
            <div className="image-gallery-empty-image">
              <img
                alt="no_image_gallery"
                style={{ maxHeight: "143px", maxWidth: "100%" }}
                src={REACT_APP_MP_NO_IMAGE_URL}
              />
            </div>
            <div className="image-gallery-empty-text small color-light">
              No Images
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PickupImagesDisplay;
