import SingleRoute from "pages/regions/routes/route-single";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Box from "components/box";
import DateDayNav from "components/date-by-day-navigation/date-by-day-navigation.component";
import { startOf } from "utils/dates";
import { REACT_APP_MP_PRIMARY_COLOR } from "config";
import Loader from "components/loader";
import { routeReportPosition } from "api/routes";

class DriverRoute extends React.Component {
  constructor(props) {
    super(props);
    // setDriverDate is not necessary
    // Set the initial Date
    // this.props.setDriverDate(props.pickupRoutes.pickupDate || new Date());
  }

  componentDidMount() {
    navigator.geolocation.watchPosition((position) => {
      this.lastFoundPos = position.coords;
    });

    this.reportingInterval = setInterval(() => {
      if (
        this.props.pickupRoutes.pickupRoutes.length !== 0 &&
        this.lastFoundPos
      )
        Promise.all(
          this.props.pickupRoutes.pickupRoutes.map((route) =>
            routeReportPosition(route.id, {
              lat: this.lastFoundPos.latitude,
              lng: this.lastFoundPos.longitude,
            })
          )
        );
    }, Number(process.env.REACT_APP_DRIVER_POSITION_REPORT_INTERVAL) * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.reportingInterval);
  }

  render() {
    const {
      isFetching,
      routesLoaded,
      pickupsLoaded,
      unnassignedPickups,
      pickupRoutes,
    } = this.props.pickupRoutes || {};
    return (
      <div>
        <section className="content">
          <Row>
            <Col sm={12}>
              <Box>
                <DateDayNav
                  date={startOf(this.props.pickupRoutes.pickupDate, "day")}
                  onChange={(date) => {
                    this.props.setDate(date, null, true);
                  }}
                  containerClassName="routes-date-picker-container"
                />
              </Box>
            </Col>
            <Col sm={12}>
              <div>
                {isFetching || !routesLoaded ? (
                  <Loader />
                ) : (
                  <div>
                    {!pickupRoutes.length ? (
                      <React.Fragment>No Route</React.Fragment>
                    ) : (
                      <React.Fragment>
                        {pickupRoutes.map((pickupRoute, routeIndex) => (
                          <SingleRoute
                            pickupDate={this.props.pickupRoutes.pickupDate}
                            routeId={pickupRoute.id}
                            pickupRoute={pickupRoute}
                            pickups={this.props.pickups.filter(
                              (pickup) => pickup.routeId === pickupRoute.id
                            )}
                            routeIndex={1}
                            color={REACT_APP_MP_PRIMARY_COLOR}
                            noCollapse={true}
                            pickupsMin={this.props.pickupsMin.filter(
                              (pickup) => pickup.routeId === pickupRoute.id
                            )}
                          />
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}
export default DriverRoute;
