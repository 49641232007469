import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker, adv } from "@react-google-maps/api";
import { REACT_APP_MP_GOOGLE_MAPS_API } from "config";
const mapConfig = {
  id: "driver-map",
  googleMapsApiKey: REACT_APP_MP_GOOGLE_MAPS_API,
  libraries: ["drawing", "geometry", "places"],
};

export const RenderDetails = ({ location, pickupType, title }) => {
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader(mapConfig);
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div className="driver-pickup-details">
      <div className="title">
        <strong>{title}</strong>
      </div>
      <div className="wrapper-map-text">
        <div className="details-box driver-map">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ height: "16rem" }}
              center={location.coordinates}
              zoom={12}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <Marker position={location.coordinates} />
            </GoogleMap>
          )}
        </div>
        <div className="details-box text">
          <div className="section">
            <strong>{location.fullAddress}</strong>
            {location.unit && <div>Unit: {location.unit}</div>}
            {location.subdivision && (
              <div>Subdivision: {location.subdivision}</div>
            )}
          </div>
          <div className="section">
            <strong>Pickup Type</strong>
            <div>{pickupType} Pickup</div>
          </div>
          {location.notes && (
            <div className="section">
              <strong>Location notes</strong>
              <div>{location.notes}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
