import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "actions/login.actions";
import Modal from "components/modal";
import UpdateDonorProfile from "forms/update-donor-profile";
import { UserContext } from "contexts/user";
import { REACT_APP_MP_LOGO_URL } from "config";

const CustomToggle = React.forwardRef(({ user, userType, onClick }, ref) => (
  <div
    className={"user-dropdown"}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className={"user-dropdown-avatar"}>
      <div className={"user-dropdown-avatar-icon"}>
        <img style={{ width: 48, height: 48 }} src={user.profileImage} alt="" />
      </div>
    </div>
    <div className={"user-dropdown-info"}>
      <div className={"user-dropdown-name"}>
        {user.fname} {user.lname}
      </div>
      <div className={"user-dropdown-type"}>{userType}</div>
    </div>
  </div>
));

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editProfile: false,
      mobileNavOpen: false,
    };
  }

  render() {
    const { logout, user, userType } = this.props;

    return (
      <>
        <div className={"top-nav driver"}>
          <div>
            <div className={"sidebar-branding driver"}>
              <img src={REACT_APP_MP_LOGO_URL} />
            </div>
          </div>

          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              className="user-dropdown"
              user={user}
              userType={userType}
            />

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => this.setState({ editProfile: true })}
              >
                Profile
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={logout}>
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Modal
            show={this.state.editProfile}
            title="Edit Profile"
            onHide={() => this.setState({ editProfile: false })}
          >
            <UpdateDonorProfile
              onCancel={() => this.setState({ editProfile: false })}
              onSubmitSuccess={() => this.setState({ editProfile: false })}
            />
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },
  userType: state.auth.credentials.userType.label,
});

const mapDispatchToProps = {
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <UserContext.Consumer>
    {({ user }) => <Header {...props} user={user} />}
  </UserContext.Consumer>
));
