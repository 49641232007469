import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Field } from "redux-form";
import FormField from "forms/FormField.js";

const FormFieldArray = ({
  fields,
  fieldKey = "",
  // pass a field key if your array is structure [{fieldKey: 1234}, {fieldKey: 4321}]
  // instead of ['someField', 'someField']
  label,
  placeholder,
  meta: { error },
  format,
  fieldNames,
  fieldLabels,
  multipleFields,
  multiFieldNormalizing,
  multiFieldTypes,
  multiFieldFormat,
  multiFieldRequired,
  multiFieldValidation,
  multiFieldDisabled,
  normalize,
  validation,
  required,
  renderCondition = true,
  rows = false,
}) => {
  const createInitialField = useCallback(
    () =>
      new Promise((res) => {
        if (fields.length < 1) {
          fields.push();
        }
      }).then(() => {
        if (multipleFields) {
          const numOfFieldsToAdd = fieldNames.length - 1;

          for (let i = 0; i < numOfFieldsToAdd; i++) {
            fields.push({});
          }
        }
      }),
    [fields, multipleFields, fieldNames]
  );

  useEffect(() => {
    createInitialField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!renderCondition) {
    return null;
  }

  return (
    <div className="form-group">
      {fields.map((field, index) => (
        <div key={index} className={"d-flex align-center  mb-4  border-bottom"}>
          {multipleFields ? (
            <>
              <div className={"w-100 mb-2"}>
                {fieldNames.map((fieldName, idx) => {
                  return (
                    <div className={"mb-3"}>
                      <Field
                        key={`${fieldName}-${idx}`}
                        name={`${field}.${fieldNames[idx]}`}
                        component={FormField}
                        label={
                          fieldLabels[idx]
                            ? fieldLabels[idx]
                            : fieldName.slice(0, 1).toUpperCase() +
                              fieldName.slice(1)
                        }
                        disabled={multiFieldDisabled?.[idx] || false}
                        format={multiFieldFormat?.[idx] || format || ((x) => x)}
                        normalize={multiFieldNormalizing?.[idx]}
                        required={multiFieldRequired?.[idx] || required}
                        validate={multiFieldValidation?.[idx] || validation}
                        type={multiFieldTypes?.[idx] || "text"}
                        style={{ paddingRight: 10 }}
                        isReports
                      />
                    </div>
                  );
                })}
              </div>
              <div>
                {fields.length > 1 && (
                  <Button
                    variant="close"
                    size={"sm"}
                    onClick={() => fields.remove(index)}
                  ></Button>
                )}
              </div>
            </>
          ) : (
            <Field
              name={fieldKey ? `${field}[${fieldKey}]` : field}
              type="text"
              component={FormField}
              removeField={
                fields.length > 1 ? () => fields.remove(index) : null
              }
              placeholder={placeholder}
              label={index === 0 ? label : `${label} #${index + 1}`}
              format={format}
              normalize={normalize}
              required={required}
              validate={validation}
            />
          )}
        </div>
      ))}

      {error && <li className="error">{error}</li>}
      <div className="add-form-control d-flex justify-content-end ">
        <Button variant="primary" onClick={() => fields.push()}>
          Add {label}
        </Button>
      </div>
    </div>
  );
};

FormField.propTypes = {
  fields: PropTypes.array,
  label: PropTypes.string,
  fieldNames: PropTypes.array,
  multipleFields: PropTypes.bool,
  multiFieldNormalizing: PropTypes.array,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.func,
};

export default FormFieldArray;
