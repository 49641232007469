/**
 * @file Render module for SingleCustomer Component
 * @author Mike Chacon
 */
import React, { useState, useEffect, useContext } from "react";
// import { withRouter } from "react-router";
import DonorInfo from "./donor-info";
import Loader from "components/loader";
import DonorLocations from "./donor-locations";
import DonorPickups from "./donor-pickups";
import DonorTimeline from "./donor-timeline";
import GeneratePin from "pages/donors/donor-single/generate-pin";
import { Container, Col, Row } from "react-bootstrap";
import Box from "components/box";
import Modal from "components/modal";
import Tabs from "components/tabs";
import { LoaderWrapper } from "components/loader";
import CreateUpdatePickup from "forms/create-update-pickup";
import Notes from "forms/notes";
import { usersNotesAPI } from "api/users";
import { UserProvider, UserContext } from "contexts/user";
import NotificationSettings from "forms/notification-settings";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "utils/helpers";

const SingleCustomerComponent = ({
  clearCustomer,
  requestCustomer,
  customer,
  createPickup,
  requestCustomerActions,
  requestPickups,
  noteCategories,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const [formLocationId, setFormLocationId] = useState(null);
  const [activeTab, setActiveTab] = useState("notes");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { customerId } = params;
  const { width } = useWindowDimensions();

  const columnFormat = (() => {
    if (width < 850) {
      return 12;
    }
    if (width < 1050) {
      return 6;
    }
    return 4;
  })();

  useEffect(() => {
    requestData();
    return () => {
      clearCustomer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openForm = (id) => {
    setDisplayForm(true);
    setFormLocationId(id);
  };
  const closeForm = () => {
    setDisplayForm(false);
    setFormLocationId(null);
  };

  const requestData = async () => {
    // Make all requests for the customer page ( TODO: Refactor in to a single endpoint for speed )
    // const {
    //   requestCustomer,
    //   // requestCustomerActions,
    //   // requestPickups,
    //   // requestLeadSources,
    //   // clearPickupBlocks
    // } = this.props;
    // const customer = await requestCustomer(this.props.match.params.customerId)
    // const customerActions = await requestCustomerActions(this.props.match.params.customerId)
    // const pickups = await requestPickups({userId: this.props.match.params.customerId})
    // const leadSources = await requestLeadSources()
    Promise.all([
      requestCustomer(customerId),

      // requestCustomerActions(this.props.match.params.customerId),
      // requestPickups({userId: this.props.match.params.customerId}),
      // requestLeadSources(),
    ]).then(() => {
      // TODO: Shouldn't be necessary
      // clearPickupBlocks();
      // Done Loading
      // setLoading(false);
    });
  };

  const { customer: user } = customer;

  //
  // // Want access to locations, but not duplicate data
  // // Index array by location Id here for easy access later
  // const locationsIndex = {};
  // locations.forEach((location, index) => {
  //   locationsIndex[location.id] = index;
  // });
  //
  // this.locationsIndex = locationsIndex;

  let isLoading = customer.isFetching || !customer.loaded;

  // Is Loading
  if (isLoading) {
    return (
      <Loader color={process.env.REACT_APP_MP_PRIMARY_COLOR} width="10rem" />
    );
  }
  // return <></>;

  return (
    <UserProvider user={user}>
      {/*This form is used to create a new pickup using the create pickup button in the location form*/}
      <Modal show={displayForm} onHide={closeForm} title="New Pickup">
        <CreateUpdatePickup
          userId={customerId}
          customerId={customerId}
          onCancel={closeForm}
          onSubmit={async (data) => {
            await createPickup(data);
            await requestCustomerActions(customerId);
            await requestPickups({ userId: customerId });
          }}
          onSubmitSuccess={closeForm}
          initialValues={{ locationId: formLocationId }}
        />
      </Modal>

      <Container fluid>
        <Row>
          <Col sm={columnFormat}>
            <h3>Donor Info</h3>
            <DonorInfo customer={user} />

            <NotificationSettingsWrapper />

            {user.userTypeId !== 5 ? (
              <Box>
                <h3 className="list-group-item-heading">Account Access</h3>
                <GeneratePin customer={user} />
              </Box>
            ) : (
              false
            )}
          </Col>

          <Col sm={columnFormat}>
            <DonorLocations
              navigate={navigate}
              locationRouter={location}
              donorId={customerId}
              formProps={{ openCreatePickupForm: openForm }}
            />
          </Col>

          <Col sm={columnFormat}>
            <DonorPickups donorId={customerId} customer={customer.customer} />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Tabs active={activeTab} onChange={(tab) => setActiveTab(tab)}>
          <Tabs.Tab value="notes">Notes</Tabs.Tab>
          <Tabs.Tab value="timeline">Timeline</Tabs.Tab>
        </Tabs>
        {activeTab === "notes" ? (
          <Box>
            <Notes
              categories={noteCategories}
              entityId={customerId}
              notesAPI={usersNotesAPI}
            />
          </Box>
        ) : (
          <DonorTimeline noteCategories={noteCategories} donorId={customerId} />
        )}
      </Container>
    </UserProvider>
  );
};

const NotificationSettingsWrapper = () => {
  const { userLoading } = useContext(UserContext);

  return (
    <LoaderWrapper loading={userLoading}>
      <Box>
        <NotificationSettings />
      </Box>
    </LoaderWrapper>
  );
};

export default SingleCustomerComponent;
