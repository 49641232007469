import LeafletSearch from "leaflet-search";
import { createControlComponent } from "@react-leaflet/core";

const createLeafletElement = (props) => {
  const sourceData = (query, setData) => {
    setData(
      props.zipList.filter(({ zipCode }) => `${zipCode}`.startsWith(query))
    );
  };

  // Create search leaflet element
  return new LeafletSearch({
    sourceData: sourceData,
    propertyName: "zipCode",
    propertyLoc: ["lat", "lng"],
    zoom: 12,
    collapsed: false,
    textPlaceholder: "Ex. 78721",
    minLength: 1,
    autoType: true,
    firstTipSubmit: true,
    tipAutoSubmit: true,
    marker: false,
  });
};

export default createControlComponent(createLeafletElement);
