import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import polyline from "@mapbox/polyline";
import { Badge } from "react-bootstrap";
import RoutePolyline from "./polylines";
import { LabelMarker, IconMarker, TruckMarker } from "./markers";
import React, { useState, useCallback } from "react";
import colors from "utils/generateColors";

import { REACT_APP_MP_GOOGLE_MAPS_API } from "config";
const mapConfig = {
  id: "google-map-script",
  googleMapsApiKey: REACT_APP_MP_GOOGLE_MAPS_API,
  libraries: ["drawing", "geometry", "places"],
};

const RouteMap = ({
  pickupRoutes,
  unnassignedPickups,
  showUnassignedOnMap,
  pickupsMap,
  routesMap,
  driverPositions,
  onMarkerClick,
}) => {
  const [zoom, setZoom] = useState(11);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [map, setMap] = useState(null);
  const [initialMapBoundsSet, setInitialMapBoundsSet] = useState(false);
  const markers = [];
  const polylines = [];

  const { isLoaded } = useJsApiLoader(mapConfig);
  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
    },
    [center]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  pickupRoutes
    .sort(function (route1, route2) {
      return route1.position - route2.position;
    })
    .map((route, routeIndex) => {
      if (!routesMap[route.id]?.pickups.length > 0) {
        return null;
      }

      // let decodedPoly = MapboxPolyline.decode(routesMap[route.id].polylines)
      polylines.push(
        <RoutePolyline
          key={`1${route.id}`}
          color={colors[routesMap[route.id].position]}
          polyline={routesMap[route.id].polylines}
        />
      );
      routesMap[route.id].pickups.forEach((pickupId, pickupIndex) => {
        // First Marker Should be Starting Location
        if (!pickupsMap[pickupId]) {
          return;
        }
        markers.push(
          <IconMarker
            key={`2${route.id}${pickupIndex}`}
            onClick={() => onMarkerClick(pickupsMap[pickupId])}
            label={pickupIndex + 1}
            color={colors[routesMap[route.id].position]}
            position={{
              lat: pickupsMap[pickupId].location.lat,
              lng: pickupsMap[pickupId].location.lng,
            }}
            InfoComponent={() => (
              <div>
                <h3>{`${pickupsMap[pickupId].user.fullName}`}</h3>
                <div>{`${pickupsMap[pickupId].location.fullAddress}`}</div>
                <div>{`${
                  isNaN(pickupsMap[pickupId].distanceFromPreviousMiles)
                    ? ""
                    : pickupsMap[pickupId].distanceFromPreviousMiles
                } miles`}</div>
                {/*<div>*/}
                {/*  <a*/}
                {/*    href={`tel:${pickupsMap[pickupId].user.phoneNumbers[0].number}`}*/}
                {/*  >{`${formatPhone(*/}
                {/*    pickupsMap[pickupId].user.phoneNumbers[0].number*/}
                {/*  )}`}</a>*/}
                {/*</div>*/}
                <Badge bg={"primary"}>
                  {pickupsMap[pickupId].pickupStatus.label}
                </Badge>
              </div>
            )}
          />
        );
        return pickupId;
      });

      markers.push(
        <LabelMarker
          key={route.id.toString() + 1}
          position={route.startCoordinates}
          label={"start"}
        />
      );
      markers.push(
        <LabelMarker
          key={route.id.toString() + 2}
          position={route.endCoordinates}
          label={"end"}
        />
      );
      return route;
    });

  const shouldDisplayMap = () => {
    if (
      !pickupRoutes.length &&
      (!unnassignedPickups.length || !showUnassignedOnMap)
    ) {
      return false;
    }

    return !pickupRoutes.some(
      // If finds one where polyline doesn't exist returns true
      ({ polylines }) => !polylines
    );
  };

  if (
    (!pickupRoutes.length &&
      (!unnassignedPickups.length || !showUnassignedOnMap)) ||
    !shouldDisplayMap()
  ) {
    return null;
  }

  if (showUnassignedOnMap)
    unnassignedPickups.map((p, index) => {
      markers.push(
        <IconMarker
          key={index}
          onClick={() => onMarkerClick(p)}
          position={{ lat: p.location.lat, lng: p.location.lng }}
          label={index + 1}
          color={"#000"}
          InfoComponent={() => (
            <div>
              <h3>{`${p.user.fullName}`}</h3>
              <div>{`${p.location.fullAddress}`}</div>
              <Badge bg={"primary"}>{p.pickupStatus.label}</Badge>
            </div>
          )}
        />
      );
      return p;
    });

  const setInitialMapBounds = () => {
    if (initialMapBoundsSet) return;
    setInitialMapBoundsSet(true);

    const pointsInInitialMapView = pickupRoutes
      .map((route) => polyline.decode(route.polylines, 6))
      .flat(1)
      .concat(
        unnassignedPickups.map((pickup) => [
          pickup.location.lat,
          pickup.location.lng,
        ])
      )
      .concat(
        Object.values(driverPositions).map((position) => [
          position.lat,
          position.lng,
        ])
      )
      .map(([lat, lng]) => new window.google.maps.LatLng(lat, lng));
    const mapInitialBounds = new window.google.maps.LatLngBounds();
    for (const point of pointsInInitialMapView) mapInitialBounds.extend(point);
    map.fitBounds(mapInitialBounds);
  };

  for (const driver of Object.values(driverPositions))
    markers.push(
      <TruckMarker
        color={colors[routesMap[driver.routeId].position]}
        position={{ lat: driver.lat, lng: driver.lng }}
        // label={`T${routesMap[driver.routeId].position}`}
        label={``}
        InfoComponent={() => (
          <div>
            <h3>{`${routesMap[driver.routeId].driver.fullName}`}</h3>
          </div>
        )}
      />
    );

  const handleCenterChanged = () => {
    if (!map) return;
    const newCenter = map.getCenter();
    setCenter(newCenter);
  };

  return (
    isLoaded && (
      <GoogleMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        zoom={zoom}
        onZoomChanged={() => {
          map && setZoom(map.getZoom());
        }}
        onCenterChanged={handleCenterChanged}
        onTilesLoaded={setInitialMapBounds}
        mapContainerStyle={{
          width: `100%`,
          height: `800px`,
        }}
        options={{
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {markers}
        {polylines}
      </GoogleMap>
    )
  );
};
export default RouteMap;
