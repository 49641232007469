import React from "react";
import { Badge, ListGroup } from "react-bootstrap";

import Icon from "../icon";
import history from "../../history";

const Item = ({
  link,
  onClick,
  icon,
  title,
  subtitle,
  subtitleArray,
  badge,
  CustomBadge,
  tags,
  muted,
  children,
  disabled,
  deleted,
  updated,
  defaultColor,
}) => (
  <ListGroup.Item
    disabled={disabled}
    onClick={(e) => {
      if (link) history.push(link);

      if (onClick) return onClick(e);
    }}
    style={{
      fontWeight: "normal",
      ...(link || onClick ? { cursor: "pointer" } : {}),
      ...(deleted ? { borderColor: "#A73939" } : {}),
      ...(updated ? { borderColor: "#2EB398" } : {}),
      ...(defaultColor ? { color: "#333" } : {}),
    }}
  >
    {children || (
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className={muted ? "text-muted" : ""}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon && (
            <div
              style={{
                fontSize: 30,
                marginRight: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon type={icon} />
            </div>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {title && <span style={{ fontWeight: "bold" }}>{title}</span>}
            {subtitle && <span>{subtitle}</span>}
            {subtitleArray &&
              subtitleArray.map((value) => <span>{value}</span>)}
          </div>
          <div>
            {CustomBadge ? (
              <CustomBadge />
            ) : (
              <Badge bg={muted ? "light" : "primary"}>{badge}</Badge>
            )}
          </div>
        </div>

        {tags &&
          Object.entries(tags).map(([label, value], idx) => (
            <div style={{ display: "flex", alignItems: "center" }} key={idx}>
              <Badge>{label}</Badge>
              <span>{value}</span>
            </div>
          ))}
      </div>
    )}
  </ListGroup.Item>
);

export default Item;
