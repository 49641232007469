import { useCallback, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import NotesDisplay from "./notes-display.component";

export const AddNoteForm = ({
  setNote,
  note,
  category,
  setCategory,
  noteCategories,
  createNote,
}) => {
  return (
    <div className="add-note-form">
      <Form>
        <Form.Group className="form-group-flex">
          <Form.Label>Category</Form.Label>
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(parseInt(e.target.value))}
          >
            {noteCategories.map((category) => (
              <option value={category.id}>{category.label}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Form.Group>

        <div className="btn-wrapper">
          <Button disabled={!note} onClick={createNote}>
            Add Note
          </Button>
        </div>
      </Form>
    </div>
  );
};

const Notes = ({
  entityId,
  notesAPI,
  setBadge,
  addNoteCountPickup,
  noteCategories,
  onChange,
}) => {
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");
  const [category, setCategory] = useState(noteCategories[0].id);

  useEffect(() => {
    if (setBadge) setBadge(notes.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes.length]);

  const updateNotes = useCallback(() => {
    notesAPI.get(entityId).then(setNotes);
  }, [entityId, notesAPI]);

  useEffect(updateNotes, [updateNotes]);

  const createNote = useCallback(() => {
    notesAPI.create(entityId, note, category).then(async () => {
      setNote("");
      await updateNotes();
      addNoteCountPickup(entityId, 1);
      onChange();
    });
  }, [entityId, note, notesAPI, updateNotes, category, addNoteCountPickup]);

  return (
    <>
      {" "}
      <NotesDisplay
        notes={notes}
        updateNote={async (note) => {
          await notesAPI.update(entityId, note);
          await updateNotes();
          onChange();
        }}
        deleteNote={async (note) => {
          await notesAPI.delete(entityId, note);
          await updateNotes();
          addNoteCountPickup(entityId, -1);
          onChange();
        }}
      />
      <AddNoteForm
        setNote={setNote}
        note={note}
        category={category}
        setCategory={setCategory}
        noteCategories={noteCategories}
        createNote={createNote}
      />
    </>
  );
};

export default Notes;
