import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import ConfirmModal from "components/confirm-modal";
import CreateRouteForm from "forms/create-route";
import UnassignedPickups from "./route-unassigned-pickups-list";
import RouteMap from "./route-map";
import SingleRoute from "./route-single";
import { Row, Col, Form, ButtonGroup, Button, Badge } from "react-bootstrap";
import Box from "components/box";
import { startOf } from "utils/dates";
import DateDayNav from "components/date-by-day-navigation/date-by-day-navigation.component";
import RouteImportExport from "forms/route-import-export";
import { REACT_APP_MP_OLD_ROUTING } from "config";
import RouteAssignPickupButton from "./route-assign-pickup-button";
import Loader from "components/loader";
import { routeGetDriverPosition } from "api/routes";
import Tabs from "components/tabs";
import { PickupTypesContext } from "contexts/pickupTypes";
import { arrayMoveElement } from "utils/helpers";

const ChangeBox = ({ changeCount, onCancel, onSave, loading }) => {
  return changeCount > 0 ? (
    <Box>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <span className={"me-2"}>Stops Updated</span>
          <Badge>{changeCount}</Badge>
        </div>
        {loading ? (
          <Loader width={"38px"} />
        ) : (
          <ButtonGroup>
            <Button onClick={onCancel} variant="outline-primary">
              Cancel
            </Button>
            <Button onClick={onSave}>Save</Button>
          </ButtonGroup>
        )}
      </div>
    </Box>
  ) : null;
};

const PickupRoutesComponent = ({
  requestUsers,
  activeRegion,
  getRegion,
  pickupRoutes,
  setDate,
  bulkDeleteRoutes,
  rehydrateRoutes,
  uploadCSV,
  auth,
  pickups,
  pickupsMin,
  sendReminderNotifications,
  pickupTypes,
  updatePickups,
  pickupsManyFetching,
}) => {
  const [driverPositions, setDriverPositions] = useState({});
  const [assigning, setAssigning] = useState(null);
  const [routeFilter, setRouteFilter] = useState(-1);
  const [mapRouteFilter, setMapRouteFilter] = useState({});
  const [showTab, setShowTab] = useState(0);
  const [showUnassignedOnMap, setShowUnassignedOnMap] = useState(true);
  const [activeRegionId, setActiveRegionId] = useState(activeRegion.region?.id);
  // unsavedChanges contains local changes which will be sent
  const [unsavedChanges, setUnsavedChanges] = useState({});
  // preChanges contains last downloaded data and is used for comparison with  unsavedChanges
  const [preChanges, setPreChanges] = useState({});
  const [changeCount, setChangeCount] = useState(0);
  const [pickupsShouldChange, setPickupsShouldChange] = useState({});

  const arrHaveSameContents = (a, b) => {
    for (const v of new Set([...a, ...b]))
      if (a.filter((e) => e === v).length !== b.filter((e) => e === v).length)
        return false;
    return true;
  };

  //create route obj with pickups (id, stopNumbee)
  useEffect(() => {
    const preChangeList = [];
    Object.keys(preChanges).map((k) => {
      preChanges[k].map((pickup) => {
        preChangeList.push(pickup.id);
      });
    });
    // protects from unneccessary state updating
    if (
      !arrHaveSameContents(
        pickupsMin.map((p) => p.id),
        preChangeList
      )
    ) {
      const routePickupsToset = {};
      pickupRoutes.pickupRoutes.forEach((route) => {
        routePickupsToset[route.id] = [];
      });
      pickupsMin
        .sort((a, b) => a.stopNumber - b.stopNumber)
        .map((pickup) => {
          const itemToPush = {
            id: pickup.id,
            stopNumber: pickup.stopNumber,
            routeId: pickup.routeId,
          };
          if (!Array.isArray(routePickupsToset[pickup.routeId])) {
            routePickupsToset[pickup.routeId] = [];
          }

          routePickupsToset[pickup.routeId].push(itemToPush);
        });
      setPreChanges(routePickupsToset);
      setUnsavedChanges(routePickupsToset);
    }
  }, [pickupsMin]);

  //check for updated pickups by stopNumber
  useEffect(() => {
    const pickupsShouldChangeToSet = [];
    // const countOfChanges = [];
    Object.keys(unsavedChanges).map((key) => {
      const result = unsavedChanges[key].map((item, index) => {
        if (preChanges[key][index]?.id === item.id) {
          return true;
        }
        pickupsShouldChangeToSet.push(item);
        return false;
      });
    });

    setChangeCount(pickupsShouldChangeToSet.length);
    setPickupsShouldChange(pickupsShouldChangeToSet);
  }, [unsavedChanges, preChanges]);

  const filteredPickupRoutes = useMemo(() => {
    return routeFilter === -1
      ? pickupRoutes.pickupRoutes
      : pickupRoutes.pickupRoutes.filter((route) => route.id === routeFilter);
  }, [pickupRoutes.pickupRoutes, routeFilter]);

  const objectPickupMap = useMemo(() => {
    const sortedPickups = pickups.sort((a, b) => a.stopNumber - b.stopNumber);
    const obj = {};
    sortedPickups.map((pickup) => {
      obj[pickup.id] = pickup;
    });
    return obj;
  }, [filteredPickupRoutes, pickups]);

  const reassignIn = (routeId) => (sourceIdx, destinationIdx) => {
    const newRoute = arrayMoveElement(
      [...unsavedChanges[routeId]],
      sourceIdx,
      destinationIdx
    ).map((movedElem, idx) => {
      return { ...movedElem, stopNumber: idx + 1 };
    });
    setUnsavedChanges({ ...unsavedChanges, [routeId]: newRoute });
  };

  const updateRoutePikup = (pickupsRoute) => {
    return pickupsRoute.map((pickup, index) => {
      return { ...pickup, stopNumber: index + 1 };
    });
  };

  const reassignFromTo =
    (routeIdFrom) => (routeIdTo, sourceIdx, destinationIdx) => {
      const elementToMove = unsavedChanges[routeIdFrom][sourceIdx];

      const newRouteFrom = [...unsavedChanges[routeIdFrom]];
      newRouteFrom.splice(sourceIdx, 1);
      const sortedNewRouteFrom = updateRoutePikup(newRouteFrom);

      const newRouteTo = unsavedChanges[routeIdTo]
        ? [...unsavedChanges[routeIdTo]]
        : [];
      newRouteTo.splice(destinationIdx, 0, elementToMove);
      newRouteTo[destinationIdx] = {
        ...newRouteTo[destinationIdx],
        routeId: routeIdTo,
      };
      const sortedNewRouteTo = updateRoutePikup(newRouteTo);

      setUnsavedChanges({
        ...unsavedChanges,
        [routeIdFrom]: sortedNewRouteFrom,
        [routeIdTo]: sortedNewRouteTo,
      });
    };

  const onCancelChanges = () => {
    setUnsavedChanges(preChanges);
  };

  const onSaveChanges = async () => {
    try {
      await updatePickups(pickupsShouldChange);
      setPreChanges(unsavedChanges);
    } catch (error) {}
  };

  const pickupRoutesFirstId = pickupRoutes.pickupRoutes[0]
    ? pickupRoutes.pickupRoutes[0].id
    : null;

  // Pull drivers in this region
  useEffect(() => {
    requestUsers({
      type: 3,
      regionId: [activeRegion.region.id, 0],
    });
    let driverInterval;
    if (REACT_APP_MP_OLD_ROUTING !== "true") {
      getDriverPositions();
      driverInterval = setInterval(getDriverPositions, 60 * 1000);
    }
    return function cleanup() {
      clearInterval(driverInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Creates 56 color array
  // TODO: Remove this in favor of setting colors on the route in the API
  // this.routeColors = [
  //   "#ff6262",
  //   "#ff62d6",
  //   "#6c62ff",
  //   "#627dff",
  //   "#62ff75",
  //   "#fdff62",
  //   "#ffaa62",
  // ];
  // for (let i = 0; i < 3; i++) {
  //   this.routeColors = this.routeColors.concat(this.routeColors);
  // }
  // }
  useEffect(() => {
    if (activeRegionId && activeRegion.region?.id !== activeRegionId) {
      getRegion({ regionId: activeRegionId });
      setDate(pickupRoutes.pickupDate, activeRegion.region?.id, true);
      setActiveRegionId(activeRegion.region?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRegionId, activeRegion.region?.id]);

  useEffect(() => {
    if (pickupRoutesFirstId) {
      if (REACT_APP_MP_OLD_ROUTING !== "true") {
        getDriverPositions(pickupRoutes);
      }
      setMapRouteFilter({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupRoutesFirstId]);

  const getDriverPositions = async () => {
    const { pickupRoutes: pickupRoutesValues } = pickupRoutes;

    const positions = {};

    // Filter down to a single route
    const filteredPickupRoutes =
      routeFilter === -1 // -1 means no filter applied
        ? pickupRoutesValues
        : pickupRoutesValues.filter((route) => route.id === routeFilter);

    // Apply a color to each route, eventually we store this in the database.
    // filteredPickupRoutes.map((route, index)=>{
    //   route.color = this.routeColors[index]
    // })

    await Promise.all(
      filteredPickupRoutes.map(async (route) => {
        const driverPosition = await routeGetDriverPosition(route.id);
        if (driverPosition) positions[route.id] = driverPosition;
      })
    );
    setDriverPositions(positions);
  };

  const deleteStops = () => {
    bulkDeleteRoutes({
      regionId: activeRegion.region.id,
      routeDate: pickupRoutes.pickupDate,
    }).then(() => {
      return rehydrateRoutes(pickupRoutes.pickupDate, activeRegion.region.id);
    });
  };

  const renderRouteForm = () => {
    const { pickupDate, unnassignedPickups } = pickupRoutes || {};

    return (
      <>
        {REACT_APP_MP_OLD_ROUTING === "true" && (
          <Tabs active={showTab} onChange={setShowTab}>
            <Tabs.Tab value={0}>Automatic</Tabs.Tab>
            <Tabs.Tab value={1}>CSV Upload</Tabs.Tab>
          </Tabs>
        )}
        <Box>
          <h3>
            Generate Route{" "}
            <small>
              ({unnassignedPickups ? unnassignedPickups.length : 0} Pickups )
            </small>
          </h3>

          <hr />

          {unnassignedPickups && unnassignedPickups.length > 0 ? (
            <>
              {showTab === 0 && (
                <CreateRouteForm
                  date={startOf(pickupDate, "day")}
                  region={activeRegion}
                />
              )}

              {REACT_APP_MP_OLD_ROUTING === "true" && showTab === 1 && (
                <RouteImportExport
                  date={pickupDate}
                  onFileSelect={uploadCSV}
                  onSuccess={() => {
                    rehydrateRoutes(pickupDate, activeRegion.region.id);
                  }}
                />
              )}
            </>
          ) : (
            <div>
              There are no pickups scheduled for today, you'll need to have some
              scheduled donations to generate a route.
            </div>
          )}
        </Box>
      </>
    );
  };

  const {
    isFetching,
    pickupsMinFetching,
    unnassignedPickups,
    pickupRoutes: pickupRoutesValues,
  } = pickupRoutes || {};
  const hasUnassignedPickups = unnassignedPickups.length > 0 ? true : false;

  const ifRoutesMoreThenOne = pickupRoutes.pickupRoutes.length > 1;
  const showUnassignedPickups =
    auth.credentials.userTypeId <= 2 && hasUnassignedPickups && auth;
  const showClearRoutes =
    pickupRoutesValues.length && auth.credentials.userTypeId === 1
      ? true
      : false;

  return (
    <section
      style={{
        marginBottom: changeCount > 0 ? "90px" : 0,
      }}
      className="content"
    >
      <div style={{ position: "sticky", top: "90vh", zIndex: 100 }}>
        <div
          style={{
            position: "absolute",
            left: "-1vw",
            width: "calc(100% + 2vw)",
          }}
        >
          <ChangeBox
            changeCount={changeCount}
            onCancel={onCancelChanges}
            onSave={onSaveChanges}
            loading={pickupsManyFetching}
          />
        </div>
      </div>

      <Row>
        <Col sm={ifRoutesMoreThenOne ? 8 : 12}>
          <Box>
            <DateDayNav
              date={startOf(pickupRoutes.pickupDate, "day")}
              onChange={(date) => {
                setRouteFilter(-1);
                setDate(date, activeRegion.region.id, true);
              }}
              containerClassName="routes-date-picker-container"
            />
          </Box>
        </Col>
        {ifRoutesMoreThenOne && (
          <Col sm={4}>
            <Box>
              <Form.Control
                as="select"
                value={routeFilter}
                onChange={(e) => {
                  setRouteFilter(parseInt(e.target.value));
                }}
              >
                <option value={-1}>All</option>
                {pickupRoutes.pickupRoutes.map((route) => (
                  <option key={route.id} value={route.id}>
                    Route {route.position + 1}
                  </option>
                ))}
              </Form.Control>
            </Box>
          </Col>
        )}
        <Col sm={12}>
          <div>
            {isFetching || pickupsMinFetching ? (
              <Loader />
            ) : (
              <div>
                <div>
                  <RouteMap
                    showUnassignedOnMap={
                      showUnassignedOnMap && pickupRoutesValues.length
                    }
                    pickupRoutes={filteredPickupRoutes.filter(
                      (route) => !mapRouteFilter[route.id]
                    )}
                    driverPositions={Object.fromEntries(
                      Object.entries(driverPositions).filter(
                        ([routeId]) => !mapRouteFilter[routeId]
                      )
                    )}
                    onMarkerClick={(pickup) => {
                      setAssigning(pickup);
                    }}
                  />
                  {assigning && (
                    <RouteAssignPickupButton
                      modalOnly={true}
                      showModal={assigning}
                      closeModal={() => setAssigning(null)}
                      pickup={assigning}
                    />
                  )}
                </div>

                {!pickupRoutesValues.length ? (
                  <React.Fragment>{renderRouteForm()}</React.Fragment>
                ) : (
                  filteredPickupRoutes.map((pickupRoute, routeIndex) => {
                    return (
                      <SingleRoute
                        key={pickupRoute.id}
                        pickupDate={pickupRoutes.pickupDate}
                        routeId={pickupRoute.id}
                        pickupTypes={pickupTypes}
                        showOnMap={!mapRouteFilter[pickupRoute.id]}
                        setShowOnMap={(show) => {
                          setMapRouteFilter({
                            ...mapRouteFilter,
                            [pickupRoute.id]: !show,
                          });
                        }}
                        pickupsObj={objectPickupMap}
                        pickupRoute={pickupRoute}
                        pickupsMin={pickupsMin.filter(
                          (pickup) => pickup.routeId === pickupRoute.id
                        )}
                        unsavedPickupsStopList={unsavedChanges[pickupRoute.id]}
                        routeIndex={routeIndex}
                        reassignIn={reassignIn(pickupRoute.id)}
                        reassignFromTo={reassignFromTo(pickupRoute.id)}
                      />
                    );
                  })
                )}
                {showUnassignedPickups ? (
                  <UnassignedPickups
                    setShowUnassignedOnMap={setShowUnassignedOnMap}
                    showUnassignedOnMap={showUnassignedOnMap}
                  />
                ) : null}

                {showClearRoutes ? (
                  <ConfirmModal
                    title={`Confirm Send Notifications`}
                    confirmText={`Are you sure you want to send reminder notifications?`}
                    confirmSubText={``}
                    onConfirm={() =>
                      sendReminderNotifications(
                        activeRegion.region.id,
                        pickupRoutes.pickupDate
                      )
                    }
                    buttonText="Send Reminder Notifications"
                  />
                ) : null}
                {"  "}
                {showClearRoutes ? (
                  <ConfirmModal
                    title={`Confirm Clear Routes`}
                    confirmText={`Are you sure you want to clear today's routes?`}
                    confirmSubText={``}
                    onConfirm={deleteStops}
                    buttonText="Clear Routes"
                  />
                ) : null}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default PickupRoutesComponent;
