import React from "react";
import { Dropdown, NavbarToggle } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "actions/login.actions";
import Modal from "components/modal";
import UpdateDonorProfile from "forms/update-donor-profile";
import { UserContext } from "contexts/user";

const CustomToggle = React.forwardRef(({ user, userType, onClick }, ref) => (
  <div
    className={"-user-dropdown container"}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className={"row"}>
      <div className={"-user-dropdown-avatar col-auto"}>
        <div className={"-user-dropdown-avatar-icon"}>
          <img
            style={{ width: 48, height: 48 }}
            src={user.profileImage}
            alt=""
            className={"rounded-circle"}
          />
        </div>
        {/*<Image className={"user-dropdown-avatar-img"} src={"http://placehold.it/50"} roundedCircle/>*/}
      </div>
      <div className={"col ps-0 text-start"}>
        <div className={"-user-dropdown-name"}>
          {user.fname} {user.lname}
        </div>
        <small>{userType}</small>
      </div>
    </div>
  </div>
));

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editProfile: false,
    };
  }

  render() {
    const { logout, user, userType, toggleNav } = this.props;

    return (
      <>
        <div
          className={"navbar py-0 bg-white sticky-top"}
          style={{ height: "5em" }}
        >
          <div className={"container-fluid p-0"}>
            <div className={"px-4"}>
              <NavbarToggle className={"d-lg-none px-4"} onClick={toggleNav} />
            </div>
            <div className={"col"}></div>
            <div className={"clickable"}>
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  user={user}
                  userType={userType}
                />

                <Dropdown.Menu>
                  {/*<Dropdown.Item eventKey="1">Settings</Dropdown.Item>*/}
                  <Dropdown.Item
                    onClick={() => this.setState({ editProfile: true })}
                  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="1" onClick={logout}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Modal
                show={this.state.editProfile}
                title="Edit Profile"
                onHide={() => this.setState({ editProfile: false })}
              >
                <UpdateDonorProfile
                  onCancel={() => this.setState({ editProfile: false })}
                  onSubmitSuccess={() => this.setState({ editProfile: false })}
                />
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },
  userType: state.auth.credentials?.userType?.label,
});

const mapDispatchToProps = {
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <UserContext.Consumer>
    {({ user }) => <Header {...props} user={user} />}
  </UserContext.Consumer>
));
